import axios from "axios";

export default axios.create({
  baseURL: "https://programming-memes-images.p.rapidapi.com",
  headers: {
    // Authorization: " Api-Key 0KQgYVcU.4FFyG0Vg2a9cNfmbz41p5NHQZP5biLAd",
    "x-rapidapi-host": "programming-memes-images.p.rapidapi.com",
    "x-rapidapi-key": "1b4714a1b5msh64bc747f887b728p155b23jsnc7e27b67a193",
  },
});

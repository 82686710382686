import React from "react";
// import unsplash from "../api/unsplash";
import meme_api from "../api/meme_api";

// import SearchBar from "./SearchBar";
// import ImageList from "./ImageList";
import MemeImageList from "./MemeImageList";
import Spinner from "./Spinner";

class App extends React.Component {
  state = { images: [] };

  async componentDidMount() {
    const response = await meme_api.get("/v1/memes");
    this.setState({ images: response.data });
  }

  onUpvoteClick = () => {
    console.log("upvote Clicked");
  };

  onDownvoteClick = () => {
    console.log("downvote Clicked");
  };

  onReportClick = () => {
    console.log("report clicked");
  };

  onMoreClick = async (term) => {
    const response = await meme_api.get("/v1/memes");
    console.log("more button clicked");
    this.setState({ images: response.data });
  };

  onSearchSubmit = async (term) => {
    const response = await meme_api.get("/v1/memes");

    console.log(response);
    this.setState({ images: response.data });
  };

  renderContent() {
    if (this.state.images.length > 0) {
      return (
        <MemeImageList
          onUpvoteClick={this.onUpvoteClick}
          onDownvoteClick={this.onDownvoteClick}
          onReportClick={this.onReportClick}
          images={this.state.images}
        />
      );
    }

    return <Spinner message="Hello World" />;
  }

  render() {
    return (
      <div className="ui container" style={{ marginTop: "10px" }}>
        {this.renderContent()}
        {/* <button onClick={this.onMoreClick}>More </button> */}
      </div>
    );
  }
}

export default App;

import React from "react";

const ImageList = (props) => {
  const images = props.images.map((image) => {
    return (
      <div key={image.id} className="card">
        <img
          style={{ width: "100%", height: "100%" }}
          alt={image.id}
          key={image.id}
          src={image.image}
        />

        <div className="extra content">
          <span className="right floated">
            <button
              className="ui mini circular basic icon button"
              onClick={props.onReportClick}
            >
              <i className="exclamation small  icon"></i>
              Report
            </button>
          </span>
          <span>
            <button
              className="ui circular basic icon button"
              onClick={props.onUpvoteClick}
            >
              <i className="blue   angle double up icon" />
            </button>
            <button
              className="ui circular basic icon button"
              onClick={props.onDownvoteClick}
            >
              <i className="red angle double down icon" />
            </button>
            75
          </span>
        </div>
      </div>
    );
  });

  return <div className="ui link cards  container">{images}</div>;
};

export default ImageList;
